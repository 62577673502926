import { config } from '$lib/config';
import {
  init,
  browserTracingIntegration,
  browserProfilingIntegration,
  replayIntegration,
} from '@sentry/svelte';

if (config.sentry.enabled) {
  init({
    dsn: config.sentry.dsn,
    environment: config.environment,
    integrations: [browserTracingIntegration(), browserProfilingIntegration(), replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/q-test.qmsmedia.io\/.*/,
      /^https:\/\/q.qmsmedia.io\/.*/,
      /^https:\/\/api\..+\.qmsmedia.io\/.*/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
